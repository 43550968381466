:root {
    --primary-dark: #231f20;
    --primary-white: #ffffff;
    --primary-color: #d71a21;
}

img {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    pointer-events: none
}

body {
    font-family: 'Open Sans', sans-serif;
}

.header {
    height: 99px;
    /* position: fixed; */
    width: 100%;
    z-index: 9999;
    background: var(--primary-white);
}

.header__content {
    background-color: var(--primary-dark);
    padding: 4px 0;
}

.container-lg {
    vertical-align: middle;
    align-items: center;
}

.logo__container {
    width: 140px;
    float: left;
    margin-right: 20px;
}

.logo__img {
    width: 140px;
    /* margin-bottom: -40px; */
}

.search__container {
    /* width: calc(100% - (120px + 40px + 1px)); */
    /* float: left; */
    min-width: min-content;
}

.search__container-body {
    display: flex;
    vertical-align: middle;
    align-items: center;
    border: 1px solid #ededed;
}

.search__container-body input {
    border: 0;
    padding: 6px 10px;
}

.search__container-body .icon {
    padding-left: 10px;
    height: 20px;
}

.search__container-body .icon i {
    font-size: 20px;
    fill: #676565;
}

.search__container-body input:focus-visible {
    outline: 0;
}

.search__container .active {
    border-color: var(--primary-color);
    color: var(--primary-dark);
    /* border-bottom: 4px solid; */
}

.search__container .navigation__item.icon {
    height: 50px;
    font-size: 22px !important;
    display: flex;
    align-items: center;
    /* padding-top: 10px !important; */
}

.search__container .navigation__item.icon span {
    font-size: 18px;
}

.search__container .navigation__item {
    font-size: 18px !important;
    height: 58px;
    /* width: 16.66666667%; */
    /* margin-right: 10px; */
    cursor: pointer;
    min-width: fit-content;
    border-right: 1px solid #dee2e6;
}

.search__container .navigation__item a {
    color: #212529;
    display: inline-block;
    width: 100%;
    padding-top: 0.9rem;
    padding-bottom: 0.85rem;
    padding-right: 20px;
    padding-left: 20px;
}

.search__container .navigation__item a.active {
    /* background-color: var(--primary-color); */
    color: var(--primary-white);
    border-color: var(--primary-color);
    color: var(--primary-color);
    border-bottom: 4px solid;
}

.search__container .navigation__item:hover>a {
    transform: scaleX(1);
    -webkit-transition-delay: .1s;
    transition-delay: .1s;
    -webkit-transition-timing-function: ease-in-out;
    transition-timing-function: ease-in-out;
    -webkit-transition-duration: .5s;
    transition-duration: .5s;
    -webkit-transition-property: all;
    transition-property: all;
    -webkit-transform-origin: left;
    transform-origin: left;
    transform-style: flat;
    color: var(--primary-white);
}

.search__container .navigation__item:hover {
    background-color: var(--primary-color);
    transform: scaleX(1);
    -webkit-transition-delay: .1s;
    transition-delay: .1s;
    -webkit-transition-timing-function: ease-in-out;
    transition-timing-function: ease-in-out;
    -webkit-transition-duration: .5s;
    transition-duration: .5s;
    -webkit-transition-property: all;
    transition-property: all;
    -webkit-transform-origin: left;
    transform-origin: left;
    transform-style: flat;
    color: var(--primary-white);
}

@media (min-width: 1440px) {
    .container-lg {
        max-width: 1410px;
    }
}

/* Shopping Cart */
.navigation__icon {
    width: 40px;
}

.navigation__icon-item {
    font-size: 22px;
    padding-top: 0.5rem;
    padding-bottom: 0rem;
    cursor: pointer;
}

/* Mobile Navigation Content */
.navigation__content ul {
    list-style-type: none;
    margin: 0;
    display: flex;
    /* justify-content: space-between; */
    padding: 0;
}

.navigation__content ul li {
    width: auto;
}

.navigation__content ul li a {
    text-decoration: none;
    color: var(--primary-dark);
    position: relative;
    padding: 10px 20px;
    border-left: 1px solid var(--primary-dark);
    /* border-right: 1px solid var(--primary-dark); */
    border-bottom: 1px solid var(--primary-dark);
    width: 100%;
}

.navigation__content ul li>a:nth-last-child(1) {
    border-right: 1px solid var(--primary-dark);
}

.navigation__content ul li:hover>a {
    background-color: var(--primary-color);
    color: var(--primary-white);
    transition: width 300ms ease-in;
}

.mobile-display {
    display: none;
}

@media (max-width: 512px) {
    .logo__container {
        padding-left: 10px !important;
    }
}

@media (max-width: 1280px) {
    .header {
        height: 70px;
    }

    .header__container {
        padding-top: 10px;
        padding-bottom: 10px;
    }

    .header__content,
    .navigation__icon,
    .search__container,
    .mobile-lg {
        display: none !important;
    }

    .mobile-display {
        display: flex;
        width: 100%;
        /* float: right; */
        justify-content: space-between;
        align-items: center;
    }

    .mobile-display .logo__container {
        /* height: 60px; */
        width: 120px;
    }

    .mobile-display .logo__img {
        height: 100%;
        width: 100%;
    }

    .mobile-menu {
        font-size: 28px;
        cursor: pointer;
        height: 34px;
    }

    .category__item img {
        height: 100% !important;
        width: 100% !important;
    }

    /* Featured Image */
    .featured-space {
        padding: 3rem 10px !important;
    }

    .featured-image img {
        height: 100% !important;
        width: 100% !important;
    }

    .featured-space-content {
        grid-template-columns: repeat(1, minmax(0, 1fr)) !important
    }

    /* Navigation */
    .nav__container {
        display: none !important;
    }

    /* Visualizer Prime */

    .prime-visualizer-1 {
        order: 1;
    }

    .prime-visualizer-2 {
        order: 2;
    }

    .content__title {
        margin-top: 50px !important;
    }

    .content__btn {
        margin-bottom: 50px;
    }

    /* Widget */
    .widget__container {
        padding: 3rem 10px !important;
    }

    .widget__title h3 {
        font-size: 22px !important;
    }

    .widget__item-title {
        padding-top: 10px;
    }

    /* Client */
    .client__container {
        padding: 3rem 10px !important;
    }

    .client__body {
        grid-template-columns: repeat(3, minmax(0, 1fr)) !important
    }

    /* Testimonial */
    .testimonial__container {
        padding: 3rem 10px !important;
    }

    .image-container img {
        width: 120px !important;
        height: 120px !important;
    }
}

/* Navigation */
.nav__container {
    background-color: #231f20;
    position: relative;
}

.nav__content ul {
    display: flex;
    list-style-type: none;
    justify-content: space-between;
    padding: 0;
    position: relative;
}

.nav__content ul li {
    min-width: fit-content;
}

.nav__content ul li a {
    text-decoration: none;
    padding: 10px;
    color: #676565;
    display: block;
}

.nav__content ul li a:hover {
    background-color: #000;
}

.nav__content ul .submenu__content {
    height: 550px;
    position: absolute;
    top: 44px;
    left: 0;
    bottom: auto;
    right: auto;
    display: none;
    background-color: #666;
    width: 100%;
    z-index: 4;
    padding: 20px;
    overflow-y: auto;
}

.nav__content ul .submenu__content a {
    color: white;
}

.nav__content ul .submenu__content ul {
    display: block;
}

.submenu__content-item ul li a {
    padding: 2px 10px;
    color: #d1cdcd !important;
}

.nav__content ul .submenu__content a:hover {
    background-color: transparent;
}

.nav__content ul li:hover>.submenu__content {
    display: flex !important;
    justify-content: space-between;
}

.nav__sticky {
    position: fixed;
    display: block;
    width: 100%;
    height: 44px;
    z-index: 99999999;
    top: 0;
}

/* Banner Slider */
.banner__container {
    height: auto;
    /* padding-top: 90px; */
}

.banner__container .swiper-pagination-bullet {
    width: 20px;
    height: 20px;
}

.banner__container .swiper-pagination-bullet-active,
.featured-space-content .swiper-pagination-bullet-active {
    background: var(--primary-color);
}

.featured-space-content {
    -webkit-transform: scale(1);
    transform: scale(1);
    display: grid;
    gap: 20px;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    -webkit-transition: -webkit-transform 8000ms ease;
    transition: -webkit-transform 8000ms ease;
    transition: transform 8000ms ease;
    transition: transform 8000ms ease, -webkit-transform 8000ms ease;
}

.featured-space-content .swiper-slide-active img {
    -webkit-transform: scale(1.3);
    transform: scale(1.3);
    transition: transform 5000ms ease;
}

/* featured products  */
.featured-products {
    margin-top: 50px;
}

.featured-products h2 {
    font-size: 28px;
    margin-bottom: 10px;
    color: var(--primary-dark);
}

.featured-products p {
    font-size: 18px;
    margin-bottom: 32px;
    font-weight: 300;
    text-align: justify;
}

.featured-products button {
    background: var(--button-color);
    border-radius: 0;
    padding: 11px 16px;
    color: var(--primary-white);
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 28px;
    /* width: 70; */
}

.featured-products button:hover {
    color: var(--primary-white);
    background: var(--button-hover);
}

.featured-products-content {
    font-size: 16px;
    font-weight: 300;
    color: var(--paragraph-color);
}

/* .featured-products-content p{
      margin-right: 1px;
    } */
.featured-products-content a {
    font-size: 18px;
    font-weight: 300;
    text-decoration: none;
    border-bottom: 1px solid var(--button-color);
    color: var(--paragraph-color);
    margin-bottom: 28px;
    margin-left: 5px;
}

/* featured space section */
.featured-space {
    /* margin-top: 30px; */
    padding: 8.5rem 0;
    background-color: #ededed;
}

.featured-products .featured-space-heading h3 {
    color: rgba(0, 0, 0, 0.8);
    margin-bottom: 0 0 8px;
}

.featured-space-heading h3 {
    font-size: 32px;
    font-weight: 700;
}

.featured-space-heading h3 span {
    color: var(--primary-color);
}

.featured-space-details p {
    font-size: 20px;
    font-weight: 300;
    font-size: 18px;
    color: rgba(0, 0, 0, 0.76);
    width: 80%;
    margin: auto;
    margin-bottom: 34px;
    text-align: center;
}

.featured-image {
    overflow: hidden;
    border-top-left-radius: 20px;
}

.featured-image img {
    height: 300px;
    width: 100%;
}

.featured-image img:hover {
    transform: scale(1.1);
    transition: 0.3s ease-in-out;
}

.featured-item {
    cursor: pointer;
}

.featured-item:hover>.featured-image img {
    -webkit-transform: scale(1.3);
    transform: scale(1.3);
    transition: transform 5000ms ease;
}

.featured-space-content .tile__item {
    font-size: 16px;
    border-bottom-right-radius: 20px;
    font-weight: 500;
    letter-spacing: 1px;
    /* margin-bottom: 40px; */
    padding: 10px;
    text-transform: uppercase;
    color: var(--primary-white);
    background-color: var(--primary-color);
}

.featured-space-content .tile__item h5 {
    margin-bottom: 0;
}

/* Footer */
.footer {
    padding: 30px 0;
    padding-bottom: 60px;
    /* marx`gin-top: 40px; */
    background-color: var(--primary-dark);
    box-shadow: 0px 30px 10px 32px #d71a21;
}

.footer-container {
    padding: 30px 0;
    padding-bottom: 60px;
    /* margin-top: 40px; */
    background-color: var(--primary-dark);
}

.footer__logo {
    padding-bottom: 20px;
    /* padding-top: 30px; */
    /* text-align: center; */
}

.footer .about__summary {
    text-align: justify;
    color: var(--primary-white);
}

/* .footer__contact, .footer__navigation {
      margin: 2rem 0;
} */
.footer__navigation h2,
.footer__contact h2 {
    font-size: 18px;
}

.footer__navigation ul {
    padding: 0;
    list-style: none;
    margin-bottom: 0;
}

.footer__navigation ul li a {
    text-decoration: none;
    color: #acacac;
    position: relative;
}

.footer__navigation ul li:hover>a {
    color: var(--primary-color);
    transition: 300ms ease-in-out;
}

.footer__contact ul {
    margin: 0;
    padding-left: 0;
    /* padding: 0; */
    /* float: left; */
}

.footer__contact ul li {
    /* display: flex; */
    margin-bottom: 5px;
    list-style: none;
    width: 100%;
}

.footer__contact box-icon {
    fill: var(--primary-white);
}

.footer__contact span {
    color: #acacac;
    /* padding-left: 10px; */
}

.footer__socialmedia ul {
    padding-top: 1rem;
    margin-bottom: 0;
    padding-left: 0;
    display: flex;
    list-style: none;
}

.footer__socialmedia ul li box-icon {
    fill: var(--primary-white);
    margin-right: 10px;
}

.contact__btn {
    background-color: transparent;
    color: var(--primary-white);
    padding: 8px 20px;
    border-color: var(--primary-color);
    /* margin-top: 15px; */
}

.contact__btn:hover {
    border-color: var(--primary-color) !important;
    background-color: var(--primary-color);
    transition: all ease-in-out;
}

@media (max-width: 1280px) {
    .footer {
        padding-left: 10px;
        padding-right: 10px;
    }

    .footer__navigation ul {
        display: block;
        /* text-align: center; */
        margin-bottom: 30px;
    }
}

/* Mobile Navigation */
.offcanvas {
    z-index: 9999999;
    background-color: var(--primary-color);
    /* width: 300px !important; */
}

.offcanvas-header {
    background-color: var(--primary-color);
    color: var(--primary-white);
    border-bottom: 1px solid var(--primary-white);
}

.bx-x {
    color: var(--primary-white);
    font-size: 28px;
    cursor: pointer;
}

.offcanvas-body .navigation__content ul {
    display: block;
    padding-left: 10px;
}

.offcanvas-body .navigation__content ul li {
    padding-top: 10px;
    cursor: pointer;
    font-size: 20px;
    color: var(--primary-white);
}

.offcanvas-body .navigation__content ul li:hover {
    color: var(--primary-dark);
    cursor: pointer;
}

.offcanvas-body .navigation__content ul li a {
    width: 100%;
}

/* Subscriber */
#newsletterSection {
    background: var(--primary-color);
    padding: 1rem 0;
    margin-top: 40px;
}


.newsletter-title {
    color: #fff;
    font-size: 1rem;
    font-weight: 500;
    text-transform: uppercase;
    margin-bottom: 15px;
}

.subscribe-txt {
    font-size: 20px;
    font-weight: 500;
    color: var(--primary-white);
    margin-right: 15px;
}

.subscribe-block {
    position: relative;
}

.callblock24 {
    background: #fff;
    padding: 8px 40px;
    font-size: 1.4rem;
    font-weight: 500;
    display: flex;
    align-items: center;
    /* border-radius: 30px; */
    max-width: fit-content;
}

.callblock24 a {
    color: #444;
    font-size: 16px;
    text-decoration: none;
}

img.img24 {
    width: 40px;
    margin-right: 15px;
}

#subscribeForm input {
    padding: 8px 10px;
    width: 200px;
    outline: none;
    background: var(--primary-white);
    border: none;
    font-size: 16px;
}

button.subscribe {
    padding: 8.13px 25px;
    border: none;
    background: var(--primary-dark);
    color: #fff;
    border-left: 1px solid var(--primary-white);
    /* position: absolute; */
    /* right: 0; */
    text-transform: uppercase;
}

/* Inspiration */
/* //overlay component css */
.overlay {
    position: relative;
    overflow: hidden;
}

.overlay-content {
    position: absolute;
    /* top:0; */
    left: 20px;
    bottom: 10px;
    color: var(--primary-white);
}

.overlay-content h3 {
    font-size: 35px;
    text-transform: uppercase;
}

.overlay img:hover {
    transform: scale(1.1);
    -webkit-transition: -webkit-transform 5000ms ease;
    transition: -webkit-transform 5000ms ease;
    transition: transform 5000ms ease;
    transition: transform 5000ms ease, -webkit-transform 5000ms ease;
}

.overlay:hover {
    background-color: rgb(229, 221, 221);
}

/* featured products css */
.visuals {
    margin: 0 40px;
}

/* inspiration css */
.inspiration {
    background: var(--primary-color);
    margin: 40px 0;
}

.inspiration h2 {
    color: var(--primary-white);
    font-weight: 400;
    font-size: 32px;
    padding: 40px;
}

/* device app csss */

.apps-button button {
    background: var(--primary-dark);
    color: var(--primary-white);
    text-transform: uppercase;
    border-radius: 2px;
    margin-top: 50px;
    margin-right: 20px;
    font-size: 14px;
    font-weight: 500;
    padding: 10px 25px;
    border: none;
}

.apps-button button i {
    font-size: 30px;
}

.apps-button button:hover {
    background: visible;
    /* border: 1px solid white; */
    color: var(--primary-white);
}

/* Footer Policy */
.footer__privacy-container {
    padding: 10px 0;
    background-color: #2e2b2b;
}

.footer__privacy {
    color: var(--primary-white);
    text-align: center;
    display: flex;
    justify-content: space-between;
}

/* Visulizer Banner */
.visulizer__banner {
    padding: 10px 0;
    font-size: 24px;
    text-transform: uppercase;
    color: var(--primary-white);
    text-align: center;
}

/* .swiper-button-next {
    left: auto !important;
    right: 20px !important;
    border-left: 1px solid var(--primary-dark);
}

.swiper-button-prev {
    right: 68px !important;
    left: auto !important;
}

.swiper-button-next, .swiper-button-prev {
    background-color: var(--primary-white);
    padding: 20px 24px;
    top: auto !important;
    bottom: 20px !important;
    color: var(--primary-dark) !important;
}

.swiper-button-next:after, .swiper-button-prev:after {
    font-size: 22px !important;
}

.swiper-button-next:hover, .swiper-button-prev:hover {
    background-color: var(--primary-color);
    color: var(--primary-white) !important;
} */

.slick-slide>div {
    margin: 0 10px;
}

.slick-list {
    margin: 0 -10px;
}

.slick-prev:before,
.slick-next:before {
    color: #666 !important;
}


/* Header */

.header__sticky {
    -webkit-animation: smooth-header .65s linear;
    animation: smooth-header .65s linear;
    background: #fff;
    box-shadow: 0 0 7px rgb(0 0 0 / 15%);
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 99;
}

/* Category */
.category__slider {
    padding: 30px 0;
}


.category__item img {
    border-top-left-radius: 10px;
    height: 120px;
    width: 320px;
    /* border-top-right-radius: 10px; */
}

.category__item p {
    border-bottom-right-radius: 10px;
    background-color: var(--primary-color);
    width: 100%;
    color: var(--primary-white);
    font-size: 16px;
    margin: 0;
    text-align: center;
    padding: 6px 0;
}

/* Content */
.content__title {
    margin-top: 100px;
}

.content__title h1 {
    font-weight: 700;
}

.content__title span {
    color: var(--primary-color);
}

.content__description {
    font-size: 18px;
    margin-top: 20px;
    margin-bottom: 20px;
}


.content__btn button {
    border-radius: 30px;
    padding: 10px 50px;
    border: 1px solid var(--primary-color);
    background-color: transparent;
    display: inline-block;
}

.contact__btn button:hover {
    color: white;
    background-color: var(--primary-color);
}

/* WIdget */
.widget__container {
    padding: 8.5rem;
    background-color: #f3f3f4;
}

.widget__title {
    /* margin-top: 50px; */
    margin-bottom: 50px;
}

.widget__title h3 {
    text-align: center;
    font-size: 32px;
    font-weight: 700;
}

.widget__title h3 span {
    color: var(--primary-color);
}

.widget__description {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    gap: 20px;
}

.widget__item-title {
    font-size: 24px;
    font-weight: 600;
    text-align: center;
}

.widget__item-title span {
    color: var(--primary-color);
}

/* Glory */
.glory__container {
    padding: 8.5rem;
    background-color: #ededed;
}

.glory__title {
    /* margin-top: 50px; */
    margin-bottom: 50px;
}

.glory__title h3 {
    text-align: center;
    font-size: 32px;
    font-weight: 700;
}

.glory__title h3 span {
    color: var(--primary-color);
}

.glory__description {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    gap: 30px;
    margin-bottom: 30px;
}

.glory__item video {
    width: 100%;
}

.glory__item-title {
    font-size: 24px;
    font-weight: 600;
    text-align: center;
}

/* Testimonial */
.testimonial__container {
    padding: 8.5rem;
    background-color: #ededed;
}

.testimonial__title h3 {
    text-align: center;
    font-size: 32px;
    font-weight: 700;
}

.testimonial__title h3 span {
    color: var(--primary-color);
}

.testimonial__item {
    display: flex;
    position: relative;
    margin-bottom: 20px;
}

.testimonial__circle {
    height: 140px;
    width: 140px;
    background-color: #d71a21;
    border-radius: 50%;
}

.testimonial {
    margin: 50px;
}

.testimonial__body {
    margin-top: 50px;
}

.testimonial__content {
    padding-top: 20px;
    padding-bottom: 20px;
}

.c-shape {
    width: 180px;
    height: 200px;
    border-top-left-radius: 100px 100px;
    border-bottom-left-radius: 100px 100px;
    border: 15px solid #cc2424;
    border-right: none;
}

.image-container {
    margin-left: -89px;
    margin-right: 20px;
}

.image-container img {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    object-fit: cover;
}

.content {
    padding: 10px;
    height: 145px;
    border-radius: 40px;
    background-color: rgba(44, 44, 44, 0.5);
}

.content h3 {
    font-size: 14px;
    font-weight: 400;
    color: #e9e7e7;
}

.content span {
    font-size: 18px;
    font-weight: 600;
    color: rgb(131, 23, 23);
}

.content p {
    font-size: 14px;
    color: #d3d3d3;
}

.content {
    border: 1px solid rgb(235, 235, 235);
}

/* Client */
.client__container {
    padding: 8.5rem;
    background-color: #fafafa;
}

.client__title {
    /* margin-top: 50px; */
    margin-bottom: 50px;
}

.client__title h3 {
    text-align: center;
    font-size: 32px;
    font-weight: 700;
}

.client__title h3 span {
    color: var(--primary-color);
}

.client__body {
    display: grid;
    grid-template-columns: repeat(6, minmax(0, 1fr));
    gap: 20px;
    margin-bottom: 30px;
    justify-content: center;
}

.client__item {
    width: 100%;
    height: 120px;
    background-color: white;
}

.client__item img {
    height: 120px;
    width: 100%;
    object-fit: contain;
    padding: 20px 10px;
}

/* Content */
.content__container {
    background-color: #fafafa;
}

.desktop__footer-copyright {
    display: inline-block;
}

.desktop__footer-menu {
    display: block;
}

/* Footer Category */
.footer__category {
    display: flex;
    color: var(--primary-white);
    justify-content: space-between;
}

.footer__item {
    display: block;
    list-style: none;
    margin-left: 0;
    padding-left: 0;
    gap: 10px;
    margin-bottom: 5px;
}

.footer__subcategory {
    display: block;
    list-style: none;
    margin: 0;
    margin-top: 10px;
    padding: 0;
}

.footer__subcategory-item a {
    padding: 2px 0;
    font-size: 14px;
    color: #acacac;
    position: relative;
    /* border-right: 1px solid #acacac; */
}

.footer__subcategory-item a:hover {
    color: #d71a21;
}

.footer__subcategory-item:last-child {
    border: none;
}

.navigation__item .material-symbols-outlined {
    line-height: 1.1 !important;
}

/* Desktop */
.footer__category-padding {
    padding-top: 3rem;
    padding-bottom: 1.5rem;
}
.mobile__footer-menu {
    display: none;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    .container {
        padding: 0 30px;
    }

    .slick-disabled {
        display: none !important;
    }

    .client__body {
        grid-template-columns: repeat(2, minmax(0, 1fr)) !important
    }

    .widget__item-title {
        font-size: 16px;
    }

    .footer__privacy {
        justify-content: center;
        display: grid;
    }

    .footer__privacy-container .container {
        padding: 0 10px;
    }

    .footer .nav-item {
        border-right: 1px solid;
        background: #f2f2f2;
    }

    .footer .nav-tabs .nav-link {
        border-radius: 0;
        padding: 5px;
    }

    .footer .nav-tabs {
        background-color: transparent;
    }

    .footer .nav-link:hover, .footer .nav-link.active {
        background-color: #d71a21 !important;
        color: white !important;
    }

    .mobile__footer-menu {
        display: inline;
    }

    .desktop__footer-menu,.desktop__footer-copyright {
        display: none;
    }

    .footer-container .accordion-item {
        background-color: transparent;
        border: 0;
    }

    .footer-container .accordion-button:not(.collapsed) {
        background-color: transparent;
        box-shadow: none;
    }

    .footer-container .accordion-button {
        background-color: transparent;
        color: white;
        padding-top: 10px;
        padding-bottom: 10px;
    }

    .footer__category-padding {
        padding: 0;
    }

    .footer-container {
        padding-bottom: 30px;
        padding-top: 40px;
    }

    .footer {
        padding: 0 25px;
    } 
    .footer-container .container {
        padding: 0 10px ;
    }
}
